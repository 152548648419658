import { default as ceo_45messageoIOaDBMNM1Meta } from "/var/www/kcpower/pages/about/ceo-message.vue?macro=true";
import { default as overviewOO4ilz4zl3Meta } from "/var/www/kcpower/pages/about/overview.vue?macro=true";
import { default as way_45comeMJFr0JgeiNMeta } from "/var/www/kcpower/pages/about/way-come.vue?macro=true";
import { default as adminENhNp8uyS8Meta } from "/var/www/kcpower/pages/admin.vue?macro=true";
import { default as _91id_93AHyhVG9uVxMeta } from "/var/www/kcpower/pages/cs/[announcement]/[id].vue?macro=true";
import { default as createSnaeS0EVYuMeta } from "/var/www/kcpower/pages/cs/[announcement]/create.vue?macro=true";
import { default as _91id_93htyOx0xjGFMeta } from "/var/www/kcpower/pages/cs/[announcement]/edit/[id].vue?macro=true";
import { default as indexTfzEG2pqs0Meta } from "/var/www/kcpower/pages/cs/[announcement]/index.vue?macro=true";
import { default as contactzIPHSvlnk4Meta } from "/var/www/kcpower/pages/cs/contact.vue?macro=true";
import { default as job_45introyUDB3d1AgRMeta } from "/var/www/kcpower/pages/hr/job-intro.vue?macro=true";
import { default as processWb3nn1ERPnMeta } from "/var/www/kcpower/pages/hr/process.vue?macro=true";
import { default as indexWJ4SyzggiqMeta } from "/var/www/kcpower/pages/index.vue?macro=true";
import { default as catalog7dS9v9GjFDMeta } from "/var/www/kcpower/pages/pr/catalog.vue?macro=true";
import { default as presssurip5jX3pMeta } from "/var/www/kcpower/pages/pr/press.vue?macro=true";
import { default as productzmVLQXqdugMeta } from "/var/www/kcpower/pages/product.vue?macro=true";
export default [
  {
    name: ceo_45messageoIOaDBMNM1Meta?.name ?? "about-ceo-message",
    path: ceo_45messageoIOaDBMNM1Meta?.path ?? "/about/ceo-message",
    meta: ceo_45messageoIOaDBMNM1Meta || {},
    alias: ceo_45messageoIOaDBMNM1Meta?.alias || [],
    redirect: ceo_45messageoIOaDBMNM1Meta?.redirect,
    component: () => import("/var/www/kcpower/pages/about/ceo-message.vue").then(m => m.default || m)
  },
  {
    name: overviewOO4ilz4zl3Meta?.name ?? "about-overview",
    path: overviewOO4ilz4zl3Meta?.path ?? "/about/overview",
    meta: overviewOO4ilz4zl3Meta || {},
    alias: overviewOO4ilz4zl3Meta?.alias || [],
    redirect: overviewOO4ilz4zl3Meta?.redirect,
    component: () => import("/var/www/kcpower/pages/about/overview.vue").then(m => m.default || m)
  },
  {
    name: way_45comeMJFr0JgeiNMeta?.name ?? "about-way-come",
    path: way_45comeMJFr0JgeiNMeta?.path ?? "/about/way-come",
    meta: way_45comeMJFr0JgeiNMeta || {},
    alias: way_45comeMJFr0JgeiNMeta?.alias || [],
    redirect: way_45comeMJFr0JgeiNMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/about/way-come.vue").then(m => m.default || m)
  },
  {
    name: adminENhNp8uyS8Meta?.name ?? "admin",
    path: adminENhNp8uyS8Meta?.path ?? "/admin",
    meta: adminENhNp8uyS8Meta || {},
    alias: adminENhNp8uyS8Meta?.alias || [],
    redirect: adminENhNp8uyS8Meta?.redirect,
    component: () => import("/var/www/kcpower/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: _91id_93AHyhVG9uVxMeta?.name ?? "cs-announcement-id",
    path: _91id_93AHyhVG9uVxMeta?.path ?? "/cs/:announcement()/:id()",
    meta: _91id_93AHyhVG9uVxMeta || {},
    alias: _91id_93AHyhVG9uVxMeta?.alias || [],
    redirect: _91id_93AHyhVG9uVxMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/cs/[announcement]/[id].vue").then(m => m.default || m)
  },
  {
    name: createSnaeS0EVYuMeta?.name ?? "cs-announcement-create",
    path: createSnaeS0EVYuMeta?.path ?? "/cs/:announcement()/create",
    meta: createSnaeS0EVYuMeta || {},
    alias: createSnaeS0EVYuMeta?.alias || [],
    redirect: createSnaeS0EVYuMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/cs/[announcement]/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93htyOx0xjGFMeta?.name ?? "cs-announcement-edit-id",
    path: _91id_93htyOx0xjGFMeta?.path ?? "/cs/:announcement()/edit/:id()",
    meta: _91id_93htyOx0xjGFMeta || {},
    alias: _91id_93htyOx0xjGFMeta?.alias || [],
    redirect: _91id_93htyOx0xjGFMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/cs/[announcement]/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTfzEG2pqs0Meta?.name ?? "cs-announcement",
    path: indexTfzEG2pqs0Meta?.path ?? "/cs/:announcement()",
    meta: indexTfzEG2pqs0Meta || {},
    alias: indexTfzEG2pqs0Meta?.alias || [],
    redirect: indexTfzEG2pqs0Meta?.redirect,
    component: () => import("/var/www/kcpower/pages/cs/[announcement]/index.vue").then(m => m.default || m)
  },
  {
    name: contactzIPHSvlnk4Meta?.name ?? "cs-contact",
    path: contactzIPHSvlnk4Meta?.path ?? "/cs/contact",
    meta: contactzIPHSvlnk4Meta || {},
    alias: contactzIPHSvlnk4Meta?.alias || [],
    redirect: contactzIPHSvlnk4Meta?.redirect,
    component: () => import("/var/www/kcpower/pages/cs/contact.vue").then(m => m.default || m)
  },
  {
    name: job_45introyUDB3d1AgRMeta?.name ?? "hr-job-intro",
    path: job_45introyUDB3d1AgRMeta?.path ?? "/hr/job-intro",
    meta: job_45introyUDB3d1AgRMeta || {},
    alias: job_45introyUDB3d1AgRMeta?.alias || [],
    redirect: job_45introyUDB3d1AgRMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/hr/job-intro.vue").then(m => m.default || m)
  },
  {
    name: processWb3nn1ERPnMeta?.name ?? "hr-process",
    path: processWb3nn1ERPnMeta?.path ?? "/hr/process",
    meta: processWb3nn1ERPnMeta || {},
    alias: processWb3nn1ERPnMeta?.alias || [],
    redirect: processWb3nn1ERPnMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/hr/process.vue").then(m => m.default || m)
  },
  {
    name: indexWJ4SyzggiqMeta?.name ?? "index",
    path: indexWJ4SyzggiqMeta?.path ?? "/",
    meta: indexWJ4SyzggiqMeta || {},
    alias: indexWJ4SyzggiqMeta?.alias || [],
    redirect: indexWJ4SyzggiqMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/index.vue").then(m => m.default || m)
  },
  {
    name: catalog7dS9v9GjFDMeta?.name ?? "pr-catalog",
    path: catalog7dS9v9GjFDMeta?.path ?? "/pr/catalog",
    meta: catalog7dS9v9GjFDMeta || {},
    alias: catalog7dS9v9GjFDMeta?.alias || [],
    redirect: catalog7dS9v9GjFDMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/pr/catalog.vue").then(m => m.default || m)
  },
  {
    name: presssurip5jX3pMeta?.name ?? "pr-press",
    path: presssurip5jX3pMeta?.path ?? "/pr/press",
    meta: presssurip5jX3pMeta || {},
    alias: presssurip5jX3pMeta?.alias || [],
    redirect: presssurip5jX3pMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/pr/press.vue").then(m => m.default || m)
  },
  {
    name: productzmVLQXqdugMeta?.name ?? "product",
    path: productzmVLQXqdugMeta?.path ?? "/product",
    meta: productzmVLQXqdugMeta || {},
    alias: productzmVLQXqdugMeta?.alias || [],
    redirect: productzmVLQXqdugMeta?.redirect,
    component: () => import("/var/www/kcpower/pages/product.vue").then(m => m.default || m)
  }
]